// .App {
//   text-align: center;
// }

// .App-logo {
//   height: 40vmin;
//   pointer-events: none;
// }

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .App-link {
//   color: #61dafb;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }

html,
body {
  font-family: 'Nunito';

  margin: 0;
  height: 100%;

  #root {
    height: 100%;
  }
}


.loader-container {
  width: 150px;
  height: 150px;
  position: absolute;
  z-index: 100;
  left: calc((100vw - 150px)/2);
  top: calc((100vh - 150px)/2);
}

.loader-background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: #191919;
  opacity: 0.75;
}

.spinner {
  width: 100%;
  height: 100%;
  background: url('../public/logo_2.svg') no-repeat center center;
  background-size: contain;
  animation: spin 1.5s linear infinite;
  position: absolute;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.sub-title {
  font-size: 20px;
  background:  #D9E8F9;
  color: #0075ac;
  padding: 5px;
  font-weight: 700;
  padding-left: 20px;
}
.form-content {
  padding: 20px
}


.MuiOutlinedInput {
  border: none;
  border-bottom: 1px solid;
  border-radius: 0;
  border-color: #949494;
}

.date-input {
  opacity: .2;
  &.selected {
    opacity: 1;
  }
}

.test {
  width: 100%;
}
.root {
& .MuiFilledInput-input {
  padding-top: 5px;
  background: white
}
}
.send-button {
  text-align: center;
  width: max-content;
  margin: auto;
  height: 50px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  border-radius: 40px;
  padding: 15px;
  background-color: #e8424a;
  text-transform: uppercase;
  font-weight: 800;
  font-family: 'Nunito';
  letter-spacing: 1px;
  margin-top: 40px;
  cursor: pointer;
  box-shadow: 0 0 15px 5px #b1b1b1;
  border: 4px solid white;
  &:hover {
   background-color: #c63038;
  }
}

.summary-bottom-prompt {
  font-size: 12px;
  text-align: center;
  margin-top: 20px;
}
.root2 {
  & .MuiFilledInput-input {
    padding-top: 3px !important;
    background: white
  }
  & .MuiFilledInput-root {
    padding: 0 !important;
    background: white
  }
}
.init-step-label {
  margin-bottom: 15px;
}

.welcome_container {
  background-image:url('../public/foto_mikolaj/krok1-start.jpg');
  background-position: center center;
  width: 100%;
  height: 100%;
  background-size: cover;
  display: grid;
  align-items: center;
  justify-content: center;

}
.content {

}
.welcome_img {
  //height: calc(100vh - 150px)
  width: 100%;
}

.welcome_text {
  color: white;
  font-size: min(max(2vw, 20px), 40px);
  background:  #0075ac;
  padding: 20px;
  border-radius: 50px;
  font-weight: bolder;
  max-width: 300px;
  text-align: center;
  margin-top:  50%;

}

.clear-btn {
  background-color: #0075ac;
  color: #fff;
  border: 1px solid #0075ac;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  width: max-content;
  float: right;
  margin-top: 10px;
  margin-bottom: 10px;

  &:hover {
    background-color: #036490;
    color: #dcdcdc;
  }
}

.test-btn {
  display: inline-block;
  margin-left: 50px;
  cursor: pointer;
  &.disabled {
    cursor: none;
    color: grey;
  }
}
.error-prompt {
  font-size: 12px;
  color: darkred;
 // height: 13px;
}
.extra-prompt {
  font-size: 12px;
  color:  #0075ac;
 // height: 23px;
}
.summary-step-title {
  font-size: 15px;
  background: #D9E8F9;//rgb(1 117 173 / 38%);
  color: #0075ac;//#3d3d3d;
  padding: 3px;
  font-weight: 700;
  padding-left: 6px;
}
.summary-title {
  font-size: 17px;
  background: #D9E8F9;//rgb(1 117 173 / 38%);
  color: #0075ac;//#3d3d3d;
  padding: 10px;
  font-weight: 700;
 padding-left: 6px;
  text-align: center;
  border-bottom: 1px solid;
  border-top: 1px solid;
}
.summary-sub-title {
  margin: 5px;
  color: #0075ac;
  &.value {
    text-align: center;
  }
}
.summary-value {
  margin: 7px;
  &.value {
    text-align: center;
  }
}
.summary-sum {
  background: #D9E8F9;//rgb(1 117 173 / 38%);
  color: #0075ac;//#3d3d3d;
}
.summary-item-title {
  padding-left: 10px;
}
.arrow-icon {
  margin: 7px
}
.App {
  height: 100%;
  overflow: hidden;
  
  // height: 100%;
  display: grid;
  grid-template-rows: 50px 1fr 50px;

  // height: 100%;
  // display: flex;
  // flex-direction: column;
  // .content {
  //   flex: 1;
  //   overflow: auto;
  //   background: pink;
  // }

  .content {
    overflow-y: auto;
    padding: 20px;
  }
  .content-welcome-page {
    overflow: hidden;
  }
  .header {
    // background-color: #ffe030;
    // color: #0075ac;

    background-color: #0075ac;
    color: #fff;

    line-height: 50px;
    font-weight: bold;

    user-select: none;

    padding: 0 20px;

    .step-back {
      float: left;
      cursor: pointer;
      padding-right: 20px;

      @media (hover: hover) {
        &:hover {
          opacity: .7;
        }
      }

      &.disabled {
        opacity: .3;
        cursor: default;
      }
    }

    .step-title {
      text-align: center;
      .step-nr {
        margin: 0 20px;
        display: inline;
      }
    }

    .step-forward {
      float: right;
      cursor: pointer;
      padding-left: 20px;

      @media (hover: hover) {
        &:hover {
          opacity: .7;
        }
      }

      &.disabled {
        opacity: .3;
        cursor: default;
      }
    }
  }
  .footer {
    background-color: #0075ac;
    color: #fff;

    line-height: 50px;
    font-weight: bold;

    user-select: none;

    padding: 0 20px;

    text-align: center;
  }
}

.app-step {
  &.hidden {
    display: none;
  }
}

.title {
  text-align: center;
  margin: 15px;
  font-size: 20px;
  color: #f11212;
}

.sum-label {
  text-align: center;
  margin: 15px;
  font-size: 20px;
}

.item {
  font-family: 'Nunito';
  transition: opacity 1200ms, display 1200ms;
  align-content: space-between;
  height: 100%;
  display: grid;
  //margin: 20px;
  // padding: 10px 5px;
  text-align: center;
  border: 1px solid #eee;
  border-radius: 15px;
  // width: 300px;
  box-shadow: 0px 0px 15px 1px rgba(66, 68, 90, 0.23);
  // padding-bottom: 10px;

  // display: inline-block;

  user-select: none;

  // FIXME: ???
  &.not-selected {
    .item-header-image {
      img {
        // opacity: .5;
      }
    }
  }

  .item-header-image {
    //  height: 10px;
    //  width:10px;
    // background: url('demo.jpg') white no-repeat;
    display: flex;
    height: 200px;

    img {
      object-fit: cover;

      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      width: 100%;
      margin: 0;
    }
    &.vertical {
     // display: inline-flex
      img {
        object-fit: contain
      }
    }
  }

  .item-title {
    font-size: 16px;
    font-weight: bold;
    // background-color: #eee;
    padding: 10px 0;
  }

  .item-description {
    font-size: 12px;
    text-align: left;
    padding: 0 15px;
    // min-height: 90px;
    text-align: center;
  }


  .item-option {
    padding: 15px 0;

    &.with-one {
      padding-bottom: 0;
    }

    .option-question {
      font-size: 14px;
    }

    .option-values-3 {
      display: grid;
      grid-template-columns: repeat(3, calc(100%/3));

      &.values-4 {
        grid-template-columns: repeat(1, calc(100%/1));

      }

      margin-top: 10px;

      // display: grid;
      // grid-template: 100px 33% 33%/ 150px 150px 150px;
      // grid-gap: 15px 20px;
      .option-value {
        font-size: 12px;
        font-weight: bold;

        &:hover {
          background-color: #eee;
          cursor: pointer;
        }

        border: 1px solid #eee;
        padding: 5px;
        border-left: 0;

        &.selected {
          background-color: #0075ac;
          color: white;
          cursor: default;
        }
      }
    }
  }

  .item-numeric {
    padding: 5px 15px;
    display: table;

    .option-question {
      display: table-cell;
      // width: 100px;
      text-align: right;
      font-size: 14px;
    }

    .option-input {
      width: 70px;
      padding-left: 20px;
      display: table-cell;

      input {
        text-align: right;
        font-size: 14px;
      }
    }
  }

  .item-count {}

  .item-value {
    margin-top: 10px;
    height: 25px;
    .value-selected-item {
      float: left;
    }
    .value-sum {
      &.not-selected {
        opacity: .5;
      }
    }

    font-weight: bold;
    text-align: right;
    // margin-right: 20px;

    background-color: #444; //#D9E8F9;
    color: whitesmoke;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    padding: 10px 15px;
  }
}